<template>
	<transition name="slide-fade" mode="out-in">
		<Layout>
			<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
				<form class="auth__form" @submit.prevent="handleSubmit(setPassword)">
					<Logo />
					<div class="mb-5 text-center">
						<h1 class="is-size-4 is-semibold">{{ $t('auth.set_password') }}</h1>
						<!-- prettier-ignore -->
						<p>{{ $t('auth.access_platform') }}<router-link id="link-login" :to="{ name: 'Login' }" class="is-semibold pl-2">{{ $t('auth.login_platform') }}.</router-link></p>
					</div>

					<InputWithValidation tab="1" class="mb-5" rules="required|email" field="email" :label="$t('fields.email')" size="is-medium" v-model="auth.email" />
					<InputWithValidation tab="2" class="mb-5" rules="required|min:8" field="password" :label="$t('fields.password')" vid="new_password" size="is-medium" password-reveal v-model="auth.password"></InputWithValidation>
					<InputWithValidation tab="3" class="mb-5" rules="required|min:8|confirmed:new_password" field="password" :label="$t('fields.confirm_password')" size="is-medium" password-reveal v-model="auth.password_confirmation"></InputWithValidation>

					<span class="is-block text-center">
						<b-button id="button-password" tabindex="4" native-type="submit" class="button is-button is-gradient" :loading="loading">{{ $t('buttons.save') }}</b-button>
					</span>
				</form>
			</ValidationObserver>
		</Layout>
	</transition>
</template>

<script>
import Layout from '@/layouts/Auth'
import Logo from '@/components/Logo'
import InputWithValidation from '@/components/inputs/InputWithValidation'
import { ValidationObserver, extend } from 'vee-validate'
import { ToastProgrammatic as Toast } from 'buefy'
import Api from '@/services/api'

export default {
	components: {
		Layout,
		Logo,
		InputWithValidation,
		ValidationObserver
	},
	data() {
		return {
			loading: false,
			auth: {
				email: '',
				password: '',
				password_confirmation: '',
				token: this.$route.params.token
			}
		}
	},
	mounted() {
		this.validateConfirmationPassword(this.auth)
	},
	methods: {
		async setPassword() {
			try {
				this.loading = true
				const response = await Api.post('user/reset', this.auth)
				const { status } = response
				if (status === 200) {
					const { message } = response.data
					Toast.open({
						message,
						type: 'is-success',
						position: 'is-bottom-right'
					})

					setTimeout(async () => {
						await this.$router.push('/')
					}, 500)
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					const { message } = e.data
					Toast.open({
						message,
						type: 'is-danger',
						position: 'is-bottom'
					})
				}
			} finally {
				this.loading = false
			}
		},
		validateConfirmationPassword(auth) {
			extend('password', {
				validate() {
					return auth.password === auth.password_confirmation
				},
				message: this.$t('validation.password')
			})
		}
	}
}
</script>
